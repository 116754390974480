import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Banner from 'components/Banner';
import GradientText from 'components/GradientText';
import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import { ROUTES } from 'constants/routes';
import { fetchResources, selectResourcesPage, selectResourcesPageIsLoading } from 'store/slices/ResourcesSlice';
import ProductCards from 'components/ProductCards';
import { scrollToSection } from 'helpers';
import Preloader from 'Layout/Preloader';
import { useAnimation } from 'hooks/useAnimation';

const Resources = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectResourcesPageIsLoading);
    useEffect(() => {
        // @ts-ignore
        dispatch(fetchResources());
    }, [dispatch]);

    useEffect(() => {
        document.title = "Ресурсы | Unifer X"; // Set the title here

        let metaDescription = document.querySelector('meta[name="description"]');

        if (metaDescription) {
            metaDescription.setAttribute('content', 'Ознакомьтесь с нашими комплексными программами питания сельскохозяйственных культур и результатами наших испытаний, чтобы узнать, как ухаживать за вашими растениями для получения оптимальных урожаев.');
        }
    }, []);

    const {
        fs_title,
        fs_view_button,
        fs_title_background,
        agricultural_tag,
        agricultural_title,
        agricultural_description,
        crop_nutrition_programs,
        trial_results,
        information_materials,
    } = useSelector(selectResourcesPage);

    const resourcesData = [
        { slug: ROUTES.cropNutritionPrograms, ...crop_nutrition_programs },
        { slug: ROUTES.trialResults, ...trial_results },
        { slug: ROUTES.informationMaterials, ...information_materials },
    ];

    const handleClick = () => scrollToSection('product-card-container', 100);

    const container = useRef();

    useAnimation({
        items: ['.product-card'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner
                title={fs_title}
                image={fs_title_background}
                buttonText={fs_view_button}
                onClickButton={handleClick}
            />
            <section className="container">
                <div className="inner-container type-2">
                    <div className="main-text-container">
                        <LabelButton>{agricultural_tag}</LabelButton>
                        <GradientText className="has-animation">
                            <span dangerouslySetInnerHTML={{ __html: agricultural_title }} />
                        </GradientText>
                        <div className="offset-left-4 max-width-760">
                            <GradientText size="super-small" className="has-animation">
                                <span dangerouslySetInnerHTML={{ __html: agricultural_description }} />
                            </GradientText>
                        </div>
                    </div>
                </div>
            </section>

            <ProductCards data={resourcesData} />
        </div>
    );
};

export default Resources;
