import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

const RouterLink = ({ children, pathName, className='button-text' }) => {
    const path = ROUTES[pathName];

    return (
        <Link to={path} className={className}>
            {children}
        </Link>
    );
};

export default RouterLink;
