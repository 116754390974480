import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchTermsConditions,
    selectTermsConditionsPage,
    selectTermsConditionsPageIsLoading,
} from 'store/slices/AdditionalPagesSlice';
import AdditionalPage from 'pages/AdditionalPage';
import Preloader from 'Layout/Preloader';
import { useAnimation } from 'hooks/useAnimation';
import { useEffect } from'react';

const TermsAndConditions = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectTermsConditionsPageIsLoading);

    useEffect(() => {
        document.title = "Условия пользования | Unifer X"; // Set the title here

        let metaDescription = document.querySelector('meta[name="description"]');

        if (metaDescription) {
            metaDescription.setAttribute('content', 'Условия и положения компании Unifer X.');
        }
    }, []);

    const {
        fs_title,
        fs_background,
        fs_description,
        blocks = [],
        policy_title,
        flyers,
    } = useSelector(selectTermsConditionsPage);

    React.useEffect(() => {
        // @ts-ignore
        dispatch(fetchTermsConditions());
    }, [dispatch]);

    const sections = blocks.map(item => ({ block: item }));

    const contentData = [{ pdf: { policy_title, flyers } }, ...sections];

    const container = useRef();

    useAnimation({
        items: ['.with-sidebar-layout__left', '.box'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <AdditionalPage
            pageRef={container}
            title={fs_title}
            background={fs_background}
            buttonText={fs_description}
            pageContentData={contentData}
        />
    );
};

export default TermsAndConditions;
