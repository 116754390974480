import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WithSidebar from 'Layout/WithSidebar';
import Banner from 'components/Banner';
import ProductSidebar from 'components/ProductSidebar';
import BenefitsCards from 'components/BenefitsCards';
import {
    fetchCropNutritionPageCrops,
    fetchCropNutritionDetail,
    selectCropNutritionDetail,
    selectCropNutritionPageCrops,
    selectCropNutritionDetailIsLoading,
    selectCurrentProductFilterForCropNutrition,
} from 'store/slices/CropNutritionSlice';
import { useNavigate, useParams } from 'react-router-dom';
import GeneralInfoBox from 'components/GeneralInfoBox';
import { type_nutrition } from 'api/constants';
import { ROUTES } from 'constants/routes';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { useAnimation } from 'hooks/useAnimation';
import Preloader from 'Layout/Preloader';
import { scrollToSection, with404Page } from 'helpers';
import FilterByProduct from './FilterByProduct';

const DetailedOfCropNutrition = () => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const navigate = useNavigate();
    const { title, fs_view_button, fs_background, details, sidebar_text } = useSelector(selectCropNutritionDetail);
    const { sidebar_text_crop_nutrition } = useSelector(selectTranslations);
    const crops = useSelector(selectCropNutritionPageCrops);
    const loading = useSelector(selectCropNutritionDetailIsLoading);
    const currentProductFilter = useSelector(selectCurrentProductFilterForCropNutrition);

    useEffect(() => {
        document.title = title + ' | Unifer X';
    });

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchCropNutritionPageCrops({ type: type_nutrition, all: true }));
        with404Page(
            // @ts-ignore
            dispatch(fetchCropNutritionDetail({ slug, type: type_nutrition, product: currentProductFilter?.value })),
            navigate,
        );
    }, [dispatch, slug, currentProductFilter]);

    const handleClick = () => scrollToSection('this');

    const container = useRef();

    useAnimation({
        items: ['.box'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner title={title} image={fs_background} buttonText={fs_view_button} onClickButton={handleClick} />
            <section className="container this">
                <WithSidebar
                    containerType="type-1"
                    
                    Sidebar={
                        <>
                            <FilterByProduct />
                            <ProductSidebar
                                baseRoute={ROUTES.cropNutritionPrograms}
                                title={sidebar_text_crop_nutrition || sidebar_text}
                                data={crops}
                            />
                        </>
                    }
                >
                    {details?.map(({ product, detailed_data, purpose_processing, purposes }, index) => (
                        <GeneralInfoBox key={index}>
                            {product && <h3>{product}</h3>}
                            <div dangerouslySetInnerHTML={{ __html: detailed_data }} />
                            {purpose_processing && <h3>{purpose_processing}</h3>}
                            {!!purposes?.length && <BenefitsCards data={purposes} withCustomContent />}
                        </GeneralInfoBox>
                    ))}
                </WithSidebar>
            </section>
        </div>
    );
};

export default DetailedOfCropNutrition;
