import Preloader from 'Layout/Preloader';
import Banner from 'components/Banner';
import CareerForm from 'components/FormSection/Forms/CareerForm';
import GradientText from 'components/GradientText';
import JobCards from 'components/JobCards';
import MarqueeGallery from 'components/MarqueeGallery';
import MissionAndValuesSection from 'components/MissionAndValuesSection';
import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import { scrollToSection } from 'helpers';
import { useAnimation } from 'hooks/useAnimation';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCareerPage, fetchCareer, selectCareerPageIsLoading } from 'store/slices/CareerSlice';

const Career = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectCareerPageIsLoading);

    useEffect(() => {
        document.title = "Карьера | Unifer X"; // Set the title here


        let metaDescription = document.querySelector('meta[name="description"]');

        if (metaDescription) {
            metaDescription.setAttribute('content', 'Приглашаем присоединиться к компании Unifer X и стать частью будушего аграрного сообщества.');
        }
    }, []);

    const {
        fs_title,
        about_tag,
        fs_title_background,
        about_title,
        about_description,
        open_jobs_tag,
        open_jobs_title,
        git_tag,
        git_title,
        git_text,
        team_gallery,
        team_title,
        mission_large_background,
        mission_text,
        mission_cards,
        fs_view_button,
    } = useSelector(selectCareerPage);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchCareer());
    }, [dispatch]);

    const handleClick = () => scrollToSection('JobCards');
    const container = useRef();

    useAnimation({
        items: ['.job-cards'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner customH1={'Карьера'} image={fs_title_background} buttonText={fs_view_button} onClickButton={handleClick}>
                <GradientText size="middle" textColor="white">
                    <span dangerouslySetInnerHTML={{ __html: fs_title }} />
                </GradientText>
            </Banner>

            <section className="container">
                <div className="inner-container type-2">
                    <div className="main-text-container">
                        <LabelButton>{about_tag}</LabelButton>
                        <GradientText className="has-animation">
                            <span dangerouslySetInnerHTML={{ __html: about_title }} />
                        </GradientText>
                        <div className="offset-left-4 max-width-760">
                            <GradientText size="super-small" className="has-animation">
                                {about_description}
                            </GradientText>
                        </div>
                    </div>
                </div>
            </section>

            <MissionAndValuesSection
                loading={loading}
                background={mission_large_background}
                title={mission_text}
                data={mission_cards}
            />

            <section className="container JobCards">
                <div className="inner-container type-2">
                    <div className="main-text-container with-absolute-label">
                        <LabelButton>{open_jobs_tag}</LabelButton>
                        <div className="offset-left-4 max-width-760">
                            <GradientText size="middle" className="has-animation">
                                <span dangerouslySetInnerHTML={{ __html: open_jobs_title }} />
                            </GradientText>
                        </div>
                    </div>
                    <JobCards />
                </div>
            </section>

            <MarqueeGallery images={team_gallery} title={team_title} />

            <section className="container">
                <div className="inner-container type-3">
                    <div className="main-text-container">
                        <LabelButton>{git_tag}</LabelButton>
                        <GradientText size="middle" className="has-animation">
                            <p className="max-width-1024 pb-40 " dangerouslySetInnerHTML={{ __html: git_title }} />
                        </GradientText>
                        <div className="offset-left-4 max-width-760">
                            <GradientText size="super-small" className="has-animation">
                                <span dangerouslySetInnerHTML={{ __html: git_text }} />
                            </GradientText>
                        </div>
                    </div>
                </div>
            </section>

            <CareerForm />
        </div>
    );
};

export default Career;
