import Label from 'components/Label';
import TextWithIcon from 'components/TextWithIcon';
import { ROUTES } from 'constants/routes';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectPostsTypeTranslations } from 'store/slices/GeneralTranslationsSlice';

const NewsCard = ({ data, big = false }) => {
    const { title_background, title, type, slug, crated_at, time_to_read } = data;
    const postsTranslations = useSelector(selectPostsTypeTranslations);
    const translatedType = postsTranslations[type];

    const jsonLdImages = {
        '@context': 'https://schema.org',
        '@type': 'ImageObject',
        'url': 'https://api.uniferx.ru' + title_background,
        'caption': title,
    }

    return (
        <Link to={ROUTES.news + '/' + slug} className={`news-card ${big ? 'news-card--big' : ''}`}>
            <div className="news-card__image">
            <link rel="preload" as="image" href={title_background} />
                <img src={title_background} alt="news" />
            </div>
            <div className="news-card__content">
                <div className="news-card-labels">
                    <Label>{translatedType}</Label>
                </div>
                <div className="news-card__title description-demibold">{title}</div>
                <div className="news-card-date">
                    <TextWithIcon iconName="calendar" text={crated_at} />
                    <TextWithIcon iconName="clock" text={time_to_read} />
                </div>
            </div>
            <script type="application/ld+json">
                {JSON.stringify(jsonLdImages)}
            </script>
        </Link>
        
    );
};

export default NewsCard;
