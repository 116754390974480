export const ROUTES = {
    base: '/',
    products: '/products',
    aboutProduct: '/about-product',
    relatedProduct: '/related-product',
    resources: '/resources',
    cropNutritionPrograms: '/resources/crop-nutrition-programs',
    trialResults: '/resources/trial-results',
    informationMaterials: '/resources/information-materials',
    news: '/blog',
    article: '/article',
    career: '/career',
    detailJob: '/detail-job',
    uiKit: '/ui-kit',
    aboutUs: '/about-us',
    termsAndConditions: '/terms-and-conditions',
    legalInfo: '/legal-info',
    contacts: '/contacts',
    sitemap: '/sitemap',
};
