import Banner from 'components/Banner';
import GradientText from 'components/GradientText';
import NewsCards from 'components/NewsCards';
import TagsFilter from 'components/TagsFilter';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchBlog,
    fetchPostsForNews,
    selectBlogPage,
    selectBlogPageIsLoading,
    selectPostForNews,
} from 'store/slices/BlogSlice';
import { useEffect, useRef, useState } from 'react';
import ButtonShowMore from 'components/Button/ButtonShowMore';
import { scrollToSection } from 'helpers';
import { selectPostsTypeTranslations } from 'store/slices/GeneralTranslationsSlice';
import Preloader from 'Layout/Preloader';
import { useAnimation } from 'hooks/useAnimation';

const allType = 'all';

const News = () => {
    const dispatch = useDispatch();
    const [selectedTag, setSelectedTag] = useState(undefined);
    const [page, setPage] = useState(1);

    const loading = useSelector(selectBlogPageIsLoading);

    const { title, description, tags, title_background, show_all_button, show_more_button, fs_button_text } =
        useSelector(selectBlogPage);
    const postsTranslations = useSelector(selectPostsTypeTranslations);
    const { data, next } = useSelector(selectPostForNews);

    const showAll = { type: allType, name: show_all_button };

    useEffect(() => {
        document.title = "Исследования, новости и события | Unifer X"; // Set the title here

        let metaDescription = document.querySelector('meta[name="description"]');

        if (metaDescription) {
            metaDescription.setAttribute('content', 'Блог о новостях, исследованиях и событиях в сфере сельскохозяйственной отрасли от компании Unifer X.');
        }
    }, []);

    useEffect(() => {
        // @ts-ignore
        
        dispatch(fetchBlog());
        setSelectedTag(showAll);
    }, [dispatch, show_all_button]);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchPostsForNews({ tag: selectedTag?.type === allType ? null : selectedTag?.type, page }));
    }, [dispatch, selectedTag, page, show_all_button]);

    const handleClick = item => {
        setPage(1);
        setSelectedTag(item);
    };
    const handleShowMore = () => setPage(page + 1);

    const translatedTags = tags?.map(type => ({ type, name: postsTranslations[type] }));
    const tagsData = translatedTags ? [showAll, ...translatedTags] : [];

    const handleClickButton = () => scrollToSection('subscribe-card', 100);

    const container = useRef();
    useAnimation({
        items: ['.news-heading', '.news-cards'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner
                title={title}
                image={title_background}
                buttonText={fs_button_text}
                onClickButton={handleClickButton}
            />

            <section className="container">
                <div className="inner-container">
                    <div className="news-heading">
                        <GradientText size="small">
                            <div className="max-width-760">
                                <span dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                        </GradientText>
                        <TagsFilter tags={tagsData} selectedTag={selectedTag} onClick={handleClick} />
                    </div>
                    <NewsCards posts={data} />
                    <ButtonShowMore next={next} text={show_more_button} onClick={handleShowMore} />
                </div>
            </section>
        </div>
    );
};

export default News;
