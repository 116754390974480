import './styles.scss';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResources, selectResourcesPage, selectResourcesPageIsLoading } from 'store/slices/ResourcesSlice';
import { selectMainProducts } from 'store/slices/ProductsSlice';
import { scrollToSection } from 'helpers';
import Preloader from 'Layout/Preloader';
import { useAnimation } from 'hooks/useAnimation';
import Banner from 'components/Banner';
import GradientText from 'components/GradientText';
import RouterLink from 'components/RouterLink';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { type_trials } from 'api/constants';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import {
    fetchTrialResults,
    fetchTrialResultsPageList,
    // selectAllCropTrialsOptions,
    // selectCurrentProductFilterForTrialResults,
    selectTrialResultsIsLoading,
    selectTrialResultsPage,
    selectTrialResultsPageList,
    // setCurrentProductFilter,
} from 'store/slices/TrialResults';


const Sitemap = () => {
    const dispatch = useDispatch();

    const loading = useSelector(selectResourcesPageIsLoading);
    const products = useSelector(selectMainProducts);


    useEffect(() => {
        // @ts-ignore
        dispatch(fetchResources());
    }, [dispatch]);

    useEffect(() => {
        document.title = "Карта сайта | Unifer X"; // Set the title here

        let metaDescription = document.querySelector('meta[name="description"]');

        if (metaDescription) {
            metaDescription.setAttribute('content', 'Ознакомьтесь с картой сайта Unifer X');
        }
    }, []);

    const handleClick = () => scrollToSection('product-card-container', 100);

    const container = useRef();

    const {
        menu_company_text,
        menu_about_text,
        menu_news_text,
        menu_career_text,
        menu_contacts_text,
        menu_products_text,
        menu_resources_text,
        menu_crop_nutrition_text,
        menu_trial_results_text,
        menu_information_materials_text,
    } = useSelector(selectTranslations);

    const {
        fs_title,
        fs_title_background,
        fs_view_button,
        agricultural_tag,
        agricultural_title,
        agricultural_description,
        // filter_name,
        // product_select,
        // crop_select,
        // country_select,
    } = useSelector(selectTrialResultsPage);

    const crops = useSelector(selectTrialResultsPageList);

     useEffect(() => {
            // @ts-ignore
            dispatch(fetchTrialResultsPageList({ all: true, type: type_trials }));
            // @ts-ignore
            dispatch(fetchTrialResults());
    
            // dispatch(setCurrentProductFilter(null));
        }, [dispatch]);

    useAnimation({
        items: ['.product-card'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner customH1={'Карта сайта'} image={'https://api.uniferx.ru/storage/about us/aboutUsBanner.jpg'} buttonText={''} onClickButton={handleClick}>
                <GradientText size="middle" textColor="white">
                    <span dangerouslySetInnerHTML={{ __html: 'Карта сайта' }} />
                </GradientText>
            </Banner>


            <section className="container this">
                <ul className='sitemap'>

                    <li><RouterLink className='sitemap__link' pathName="aboutUs">{menu_about_text}</RouterLink></li>
                    <li><RouterLink className="sitemap__link" pathName="news">{menu_news_text}</RouterLink></li>
                    <li><RouterLink className="sitemap__link" pathName="career">{menu_career_text}</RouterLink></li>
                    <li><RouterLink className="sitemap__link" pathName="contacts">{menu_contacts_text}</RouterLink></li>
                    <li><RouterLink className="sitemap__link" pathName="sitemap">{'Карта сайта'}</RouterLink></li>
                    <li><RouterLink className="sitemap__link" pathName="cropNutritionPrograms">{menu_crop_nutrition_text}</RouterLink></li>
                    <li> <RouterLink className="sitemap__link" pathName="trialResults">{menu_trial_results_text}</RouterLink></li>
                    <li><RouterLink className="sitemap__link" pathName="informationMaterials">{menu_information_materials_text}</RouterLink></li>
                    {products.map(product => (
                        <li> <Link to={ROUTES.products + '/' + product.slug} className="sitemap__link" key={product.slug}>
                            {product.preview_title}
                        </Link>
                        </li>
                    ))}

                    {crops.map(crop => (
                        <li> <Link to={ROUTES.cropNutritionPrograms + '/' + crop.slug} className="sitemap__link" key={crop.slug}>
                        {crop.title}
                        </Link>
                        </li>
                    ))}
                </ul>

            </section>
        </div>
    );
};

export default Sitemap;
