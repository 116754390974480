import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import GradientText from 'components/GradientText';
import Banner from 'components/Banner';
import React from 'react';
import DefaultForm from 'components/FormSection/Forms/DefaultForm';
import { fetchProduct, selectProductContent, selectProductContentIsLoading } from 'store/slices/ProductsSlice';
import AboutProductPageContent from './PageContent';
import { ROUTES } from 'constants/routes';
import Preloader from 'Layout/Preloader';
import { useAnimation } from 'hooks/useAnimation';
import { with404Page } from 'helpers';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { setLastLocation } from 'store/slices/UI-Slice';
export const catalogKey = 'catalog_';

const AboutProducts = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const loading = useSelector(selectProductContentIsLoading);
    const navigate = useNavigate();
    const {
        general_information,
        general_information_title,
        // =======================
        benefits_title,
        benefits,
        benefits_videos,
        benefits_preview_video,
        benefits_video,
        benefits_preview_text,
        // =======================
        formula_description,
        formula_table,
        formula_title,
        formula_text,
        // =======================
        title,
        title_image,
        title_button,
        // =======================
        about_title,
        about_description,
        // =======================
        composition_compatibility,
        composition_compatibility_title,
        // =======================
        products_title,
        nutrition_programs_title,
        trial_results_title,
        has_related_products,
        has_nutrition_programs,
        has_trial_results,
        catalogs,
        group_slug,
    } = useSelector(selectProductContent);

    useEffect(() => {
        document.title = title + " | Unifer X"; // Set the title here
        let metaDescription = document.querySelector('meta[name="description"]');

        if (metaDescription) {
            metaDescription.setAttribute('content', 'Линейка ' + title + ' от компании Unifer X для высококачественных сельскохозяйственных решений.');
        }
        return () => {
            dispatch(setLastLocation({ pathname: location.pathname, state: group_slug }));
        };
    }, [location, group_slug]);

    useEffect(() => {
        // @ts-ignore
        with404Page(
            // @ts-ignore
            dispatch(fetchProduct({ slug })),
            navigate,
        );
    }, [dispatch, slug]);

    const global = useSelector(selectTranslations);
    const global_product_tag = global?.product_about_tag;
    const global_benefits_title = global?.benefits_title;

    const benefits_data =
        (benefits && benefits.length) || benefits_video
            ? {
                  main_title: benefits_title || global_benefits_title,
                  benefits,
                  video: benefits_videos
              }
            : null;
    const related_products = has_related_products
        ? {
              main_title: products_title,
          }
        : null;
    const nutrition_programs = has_nutrition_programs
        ? {
              main_title: nutrition_programs_title,
          }
        : null;
    const trial_results = has_trial_results
        ? {
              main_title: trial_results_title,
          }
        : null;

    const catalogs_data = catalogs?.reduce(
        (prev, catalog, index) => ({
            ...prev,
            [`${catalogKey}${index}`]: {
                main_title: catalog.title,
                data: catalog,
            },
        }),
        {},
    );
    const table_data = {
        main_title: formula_title,
        formula_table,
        formula_description,
    };

    const text_block = {
        main_title: 'formula_text',
        text: formula_text,
    };

    const pageContentData = {
        general_information: { general_information, main_title: general_information_title },
        table_data,
        text_block,
        benefits_data,
        related_products,
        composition_compatibility: { composition_compatibility, main_title: composition_compatibility_title },
        nutrition_programs,
        trial_results,
        ...catalogs_data,
    };

    const container = useRef();

    useAnimation({
        items: [],
        container,
        dependencies: [loading],
    });

    useEffect(() => {
        const schemaData = {
      "@context": window.location.href,
      "@type": "Product",
      "name": title,
      "image": [
        title_image
       ],
      "description": about_description,
      "brand": {
        "@type": "Brand",
        "name": "Unifer X"
      },
    }

      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.innerHTML = JSON.stringify(schemaData);
      document.head.appendChild(script);

      // Убираем скрипт при размонтировании компонента
      return () => {
          document.head.removeChild(script);
      };
  }, [title, title_image, about_description]);

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner
                title={title}
                image={title_image}
                buttonText={title_button}
                buttonType="find-desktop"
                to={ROUTES.contacts + '#find'}
            />

            <section className="container">
                <div className="inner-container type-2">
                    <div className="main-text-container">
                        <LabelButton>{global_product_tag}</LabelButton>
                        <GradientText className="has-animation">
                            <span dangerouslySetInnerHTML={{ __html: about_title }} />
                        </GradientText>
                        {about_description && (
                            <div className="offset-left-4 max-width-760">
                                <GradientText size="super-small" className="has-animation">
                                    <span dangerouslySetInnerHTML={{ __html: about_description }} />
                                </GradientText>
                            </div>
                        )}
                    </div>
                </div>
                <AboutProductPageContent pageContentData={pageContentData} />
            </section>
            <DefaultForm />
        </div>
    );
};

export default AboutProducts;
