import goodFace from 'images/icons/GoodfaceLogo.svg';
import './footer.scss';
import RouterLink from 'components/RouterLink';
import Logo from 'components/Logo';
import { useSelector } from 'react-redux';
import { selectMainProducts } from 'store/slices/ProductsSlice';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { ReactComponent as Icon1 } from 'assets/icons/Mail Send Envelope.svg';
import { ReactComponent as Icon2 } from 'assets/icons/Phone Mobile Phone.svg';
import { ReactComponent as Icon3 } from 'assets/icons/MapPin (1).svg';

const Footer = () => {
    const products = useSelector(selectMainProducts);
    const {
        footer_description_text,
        menu_company_text,
        menu_about_text,
        menu_news_text,
        menu_career_text,
        menu_contacts_text,
        menu_products_text,
        menu_resources_text,
        menu_crop_nutrition_text,
        menu_trial_results_text,
        menu_information_materials_text,
        footer_social_media_text,
        footer_contacts_email_text,
        footer_contacts_phone_text,
        footer_contacts_address_text,
        footer_copyright_text,
        footer_terms_conditions_text,
        footer_legal_info_text,
        footer_instagram_link,
        footer_facebook_link,
        footer_youtube_link,
    } = useSelector(selectTranslations);

    const Start = () => (
        <div className="new-footer-start">
            <Logo />
            <p className="description-medium">{footer_description_text}</p>
        </div>
    );
    const Menu = () => (
        <div className="footer-links-block">
            <div className="footer-title">{menu_company_text}</div>
            <RouterLink pathName="aboutUs">{menu_about_text}</RouterLink>
            <RouterLink pathName="news">{menu_news_text}</RouterLink>
            <RouterLink pathName="career">{menu_career_text}</RouterLink>
            <RouterLink pathName="contacts">{menu_contacts_text}</RouterLink>
            <RouterLink pathName="sitemap">{'Карта сайта'}</RouterLink>
        </div>
    );
    const Products = () => (
        <div className="footer-links-block">
            <div className="footer-title">{menu_products_text}</div>
            {products.map(product => (
                <Link to={ROUTES.products + '/' + product.slug} className="button-text" key={product.slug}>
                    {product.preview_title}
                </Link>
            ))}
        </div>
    );

    const Resources = () => (
        <div className="footer-links-block">
            <div className="footer-title">{menu_resources_text}</div>
            <RouterLink pathName="cropNutritionPrograms">{menu_crop_nutrition_text}</RouterLink>
            <RouterLink pathName="trialResults">{menu_trial_results_text}</RouterLink>
            <RouterLink pathName="informationMaterials">{menu_information_materials_text}</RouterLink>
        </div>
    );

    const Social = () => (
        <div>
            <div className="footer-title">{footer_social_media_text}</div>
            <div className="footer-social">
                <Link target="_blank" to={footer_instagram_link} className="button vk" />
                <Link target="_blank" to={footer_facebook_link} className="button tg" />
                <Link target="_blank" to={footer_youtube_link} className="button rutube" />
            </div>
        </div>
    );
    const Contacts = () => (
        <div className="footer-links-block">
            <div className="footer-title">{menu_contacts_text}</div>
            <a href={`mailto:${footer_contacts_email_text}`}  title="почта" className="footer-contact-link">
                <Icon1 />
                <span>{footer_contacts_email_text}</span>
            </a>
            <a href={`tel:${footer_contacts_phone_text}`} title="телефон"  className="footer-contact-link">
                <Icon2 />
                <span>{footer_contacts_phone_text}</span>
            </a>
            <a href="/#" title="адрес"  className="footer-contact-link">
                <Icon3 />
                <span>{footer_contacts_address_text}</span>
            </a>
        </div>
    );
    const Copy = () => (
        <div className="footer-copy">
            <p className="copyright">{footer_copyright_text}</p>
            <div className="policies">
                <RouterLink pathName="termsAndConditions">{footer_terms_conditions_text}</RouterLink>
                <RouterLink pathName="legalInfo">{footer_legal_info_text}</RouterLink>
            </div>

        </div>
    );

    return (
        <footer className="container">
            <div className="new-footer">
                <div className="new-footer-row">
                    <Start />
                    <Menu />
                    <Products />
                    <Resources />
                </div>
                <div className="new-footer-row">
                    <div></div>
                    <Social />
                    <Contacts />
                </div>
                <Copy />
            </div>
        </footer>
    );
};

export default Footer;
